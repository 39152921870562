import React from 'react';
import './Loading.css';
import './mediaQuery.css'


const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-circle">
        <div className="loading-text">Adilabad <br/> App</div>
      </div>
    </div>
  );
};

export default Loading;
